import React , { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from "./css/PopupForm.module.css"
import Toast from "../../../tools/Toast"
import useOrg from '../../api/organization';

function CreateOrgForm(){
    const {create} = useOrg();
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({ organization_name: '', base_currency: '', admin_email: '' , domain: '', subscription: {}});
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productLevels, setProductLevels] = useState({});
    const isNotSuperAdmin = useSelector((state) => state.user.role) === 'super_admin'? false: true;
    const isNotHaveToken = useSelector((state) => state.user.token) === null ? true: false;

    // TODO take from the products that the org has (maybe global? )
    const products = {
        '': [],
        'KalDocs': ['kal-docs-1'],
        'KalVoice': ['kal-voice-1'],
        'KalChat': ['kal-chat-1']
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.id]: event.target.value });
    };

    const handleProductSelect = (event) => {
        const selectedOptions = Array.from(event.target.options)
                                    .filter(option => option.selected)
                                    .map(option => option.value);

    
        setSelectedProducts(selectedOptions);
    
        // Initialize level for newly added products
        const newProductLevels = {};
        selectedOptions.forEach(product => {
            newProductLevels[product] = {level: productLevels[product] || ''}; // Keep existing level or default to empty
        });
        setProductLevels(newProductLevels);
    };

    const handleLevelChange = (product, levelValue) => {
        setProductLevels(prevLevels => ({
            ...prevLevels,
            [product]: {
                level: levelValue 
            }
        }));
    };
    
    

    const handleSubmit = async (event) => {
        formData.subscription = productLevels
        event.preventDefault(); // Prevent default form submission
        setMessage('');
        // Custom validation logic
        if (formData.organization_name === '' || formData.base_currency === '' || formData.admin_email === '' ) {
            setMessage('Please fill all the data.'); // Custom alert message
            setShowToast(true)
        } 
        else {
            try {
                if (isNotSuperAdmin && isNotHaveToken)
                {
                    setMessage("This action is restricted to users with specific authorization levels")
                    setShowToast(true);
                }
                else {
                    const response = await create(formData);
                    setMessage('created successful! with id: '+response.organization_id);
                    setShowToast(true);
                }
            } catch (error) {
                setMessage(error || 'creation failed.');
                setShowToast(true);
            }
        }
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.frame}>
                <div className={styles.form}>
                    <div className={styles.label}>
                        <div className={styles.text_wrapper}>Create New Organization</div>
                    </div>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <label htmlFor='organization_name'>Name</label>
                        <input id='organization_name' type='text' className={styles.input_field} value={formData.name} onChange={handleChange} />

                        <label htmlFor='base_currency'>Currency</label>
                        <select id='base_currency' className={styles.select_field} value={formData.currency} onChange={handleChange}>
                            <option value=''></option>
                            <option value='USD'>USD</option>
                            <option value='EUR'>EUR</option>
                        </select>

                        <label htmlFor='domain'>Domain</label>
                        <input id='domain' type='text' className={styles.input_field} value={formData.domain} onChange={handleChange} />

                        <label htmlFor='admin_email'>Admin Email</label>
                        <input id='admin_email' type='text' className={styles.input_field} value={formData.admin_email} onChange={handleChange} />

                        <label htmlFor='products'>Products</label>
                            <select id='products' multiple value={selectedProducts} onChange={handleProductSelect}>
                                {Object.keys(products).map(product => (
                                    <option key={product} value={product}>{product}</option>
                                ))}
                            </select>

                            {/* Level selectors for each selected product */}
                            {selectedProducts.map((product, index) => (
                                <div key={index}>
                                    <label htmlFor={'level-' + product}>{product} Level</label>
                                    <select id={'level-' + product} value={productLevels[product]?.level || ''} onChange={(e) => handleLevelChange(product, e.target.value)}>
                                        <option value=''></option>
                                        {products[product].map(level => (
                                            <option key={level} value={level}>{level}</option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        <button type="submit" className={styles.button}>Submit</button>
                    </form>
                </div>
            </div>
            {showToast && <Toast message={message} onClose={() => setShowToast(false)} />}
        </div>
    );
}

export default CreateOrgForm;