import React from 'react';
import styles from '../css/ProductMessage.module.css'; 
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


export function ProductMessage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { productType } = location.state || { productType: "Not SaaS" };




  return (
    <div className={styles.container}>
      <div className={styles.productMessage}>
        {productType === 'SaaS' ? (
          <div>
            <p className={styles.message}>Your account has been created successfully. You will be able to access your account shortly.</p>
            <button onClick={() => navigate('/')} className={styles.link}>Proceed to Login Page</button>
          </div>
        ) : (
          <p className={styles.message}>Your account has been created successfully. Our team will reach out to you soon with the next steps.</p>
        )}
      </div>
    </div>
  );
}
