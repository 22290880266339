import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('userToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  org_id: localStorage.getItem('org_id') || null,
  org_name: localStorage.getItem('org_name') || null,
  org_logo: localStorage.getItem('org_logo') || null,
  user_name: localStorage.getItem('user_name') || null,
  role: localStorage.getItem('role') || null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('userToken', action.payload); 
    },
    setRefreshToken:(state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem('refreshToken', action.payload); 
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.org_id = null;
      state.user_name = null;
      state.role = null;
      state.org_logo = null;
      state.org_name = null;
      localStorage.removeItem('userToken'); 
      localStorage.removeItem('refreshToken'); 
      localStorage.removeItem('role');
      localStorage.removeItem('org_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('org_logo');
      localStorage.removeItem('org_name');
    },
    setOrgId: (state, action) => {
      state.org_id = action.payload;
      localStorage.setItem('org_id', action.payload);
    },
    setOrgName: (state, action) => {
      state.org_name = action.payload;
      localStorage.setItem('org_name', action.payload);
    },
    setOrgLogo: (state, action) => {
      state.org_logo = action.payload;
      localStorage.setItem('org_logo', action.payload);
    },
    setUserName: (state, action) => {
      state.user_name = action.payload;
      localStorage.setItem('user_name', action.payload);
    },
    setRole: (state, action) => {
      state.role = action.payload;
      localStorage.setItem('role', action.payload);
    }
  },
});

export const { setToken, setRefreshToken, logout, setOrgId, setUserName, setRole, setOrgLogo, setOrgName } = userSlice.actions;
export default userSlice;
