import React, { useState } from 'react';
import NavBar from '../../../tools/Navbar';
import Support from '../Forms/Support';

function SupportPage(){
    return (
        <>
        <NavBar title={'KalSense'}/>
        <Support/>
        </>
    )
}

export default SupportPage;