import React, { useState , useEffect} from 'react';
import { useDispatch } from 'react-redux';
import styles from "../Forms/css/PopupForm.module.css";
import Toast from "../../../tools/Toast";
import { useNavigate } from 'react-router-dom';

export function SaaStudioSignup({ product, type }) {

    const [jwt, setJwt] = useState('');

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token')
        if (token) {
            setJwt(token);
        }
    }, []);

    const subscriptionDetails = {
        KalDocs: { level: "kal-docs-1" },
        KalVoice: { level: "kal-voice-1" },
        KalChat: { level: "kal-chat-1" }
    };

    const dispatch = useDispatch();
    const [docsFormData, setDocsFormData] = useState({
        organization_name: '',
        base_currency: '',
        admin_email: '',
        domain: '',
        user_name: '',
        first_name: '',
        last_name: '',
        email: '',
        role: 'admin',
        subscription: { [product]: subscriptionDetails[product] },
        product_type: type,
        product: product
    });

    const [updateFormData, setUpdateFormData] = useState({
        organization_name: '',
        admin_email: '',
        domain: '',
        subscription: { [product]: subscriptionDetails[product] },
        product_type: type,
        product: product
    });

    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [hasExistingProduct, setHasExistingProduct] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        if (hasExistingProduct) {
            setUpdateFormData({ ...updateFormData, [event.target.id]: event.target.value });
        } else {
            setDocsFormData({ ...docsFormData, [event.target.id]: event.target.value });
        }
    };

    const handleCheckboxChange = () => {
        setHasExistingProduct(!hasExistingProduct);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let formData = hasExistingProduct ? updateFormData : docsFormData;
        let isFormValid = true;
        let errorMessage = '';
    
        if (hasExistingProduct) {
            const requiredFields = ['organization_name', 'admin_email', 'domain'];
            for (const field of requiredFields) {
                if (!formData[field]) {
                    isFormValid = false;
                    errorMessage = 'Please fill all required fields for updating your account.';
                    break;
                }
            }
        } else {

            const requiredFields = ['organization_name', 'base_currency', 'admin_email', 'user_name', 'first_name', 'last_name', 'email', 'role'];
            for (const field of requiredFields) {
                if (!formData[field]) {
                    isFormValid = false;
                    errorMessage = 'Please fill all required fields for new account creation.';
                    break;
                }
            }
        }
    
        if (!isFormValid) {
            setMessage(errorMessage);
            setShowToast(true);
            return;
        }
    
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            if (jwt) {
                headers['Authorization'] = `Bearer ${jwt}`;
            }

            const response = await fetch('https://kal-studio.marketplace.kaleidoo-dev.com/create_account', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                console.log(`response: ${response}`)
                setMessage(response.message || 'Operation failed.');
                setShowToast(true);
            } else {
                setMessage(response.message || 'Operation successful!');
                setShowToast(true);
                navigate('/signup-welcome-page', { state: { productType: "SaaS" } });
            }

        } catch (error) {
            console.error('Operation failed:', error);
            setMessage(error.message || 'Operation failed.');
            setShowToast(true);
        }
    };
    
    

    return (
        <div className={`${styles.container} ${styles.modal}`}>
            <div className={`${styles.frame} ${styles.modalFrame}`}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.label}>Sign Up for {product}</div>

                    <label>
                        <input type="checkbox" checked={hasExistingProduct} onChange={handleCheckboxChange} />
                        I already have a license for Kal-Studio product.
                    </label>

                    {hasExistingProduct ? (
                        <>
                            <input id='organization_name' placeholder='Organization Name' value={updateFormData.organization_name} onChange={handleChange} />
                            <input id='admin_email' placeholder='Admin Email' value={updateFormData.admin_email} onChange={handleChange} />
                            <input id='domain' placeholder='Domain' value={updateFormData.domain} onChange={handleChange} />
                        </>
                    ) : (
                        <>
                            <input id='organization_name' placeholder='Organization Name' value={docsFormData.organization_name} onChange={handleChange} />
                            <input id='base_currency' placeholder='Base Currency' value={docsFormData.base_currency} onChange={handleChange} />
                            <input id='admin_email' placeholder='Admin Email' value={docsFormData.admin_email} onChange={handleChange} />
                            <input id='domain' placeholder='Domain' value={docsFormData.domain} onChange={handleChange} />
                            <input id='user_name' placeholder='User Name' value={docsFormData.user_name} onChange={handleChange} />
                            <input id='first_name' placeholder='First Name' value={docsFormData.first_name} onChange={handleChange} />
                            <input id='last_name' placeholder='Last Name' value={docsFormData.last_name} onChange={handleChange} />
                            <input id='email' placeholder='Email' value={docsFormData.email} onChange={handleChange} />
                            <select id='role' value={docsFormData.role} onChange={handleChange}>
                                <option value='admin'>Admin</option>
                            </select>
                        </>
                    )}
                    <button type="submit">Submit</button>
                </form>
            </div>
            {showToast && <Toast message={message} onClose={() => setShowToast(false)} />}
        </div>
    );
}
