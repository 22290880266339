import React, { useEffect, useState } from 'react';
import styles from './css/WorkSpace.module.css';
import StepperForm from './Forms/StepperForm';
import LoadingCircle from "../../tools/LoadingCircle";
import { useSelector } from 'react-redux';
import useProcessProject from '../api/ProcessProjectData';
import { MdDeleteOutline } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import ConfirmPopup from "../../tools/ConfirmPopup"
import { IoDocumentsOutline } from "react-icons/io5";
import { RiWechatLine } from "react-icons/ri";
import { BiUserVoice } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


const Workspace = ({ id, icon, label , product, handleDelete, initIsReady, type}) => {
  const [isReady, setIsReady] = useState(initIsReady);
  const [clicked, setClicked] = useState(false);
  const orgId = useSelector((state) => state.user.org_id);
  const [initLoading, setInitLoading] = useState(true);
  const [selectedMainType, setSelectedMainType] = useState('');
  const [projectData, setProjectData] = useState({ workspace_id: id, org_id: orgId ,project_type: type});
  const [sourceData, setSourceData] = useState({org_id: orgId, id: id, name: '', type: '', details: {}});
  const [destData, setDestData] = useState({org_id: orgId, id: id, name: '', type: '', attributes: {}});
  const [prevDest, setPrevDest] = useState(false);
  const [prevSource, setPrevSource] = useState(false);
  const [prevProduct, setPrevProduct] = useState(false);
  const [initComplete, setInitComplete] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [destId, setDestId] = useState('');
  const [sourceId, setSourceId] = useState('');
  const {processProjectProduct, processProjectsDestData, processSourceData} = useProcessProject();
  const navigate = useNavigate();

  const goToProject = () => {
    if (isReady) {
      navigate(`/project/${type}/${label}`, { state: { project_id: id, product: product} });
    }
  };
  const sourceTypeOptions = {email: ["gmail"]}

  let data = {
    selectedMainType: selectedMainType,
    projectData: projectData,
    sourceData: sourceData,
    destData: destData,
    prevDest: prevDest,
    prevSource: prevSource,
    prevProduct: prevProduct,
    setSelectedMainType: setSelectedMainType,
    setProjectData: setProjectData,
    setSourceData: setSourceData,
    setDestData: setDestData,
    setPrevDest: setPrevDest,
    setPrevSource: setPrevSource,
    setPrevProduct: setPrevProduct,
    sourceTypeOptions: sourceTypeOptions
  }

  useEffect(() => {
    if(clicked){
      Promise.all([
        processProjectsDestData(orgId, id, setPrevDest, setDestData).then(res => ({
          step: 2, 
          completed: res.completed, 
          id: res.id
        })),
        processProjectProduct(orgId, id, setPrevProduct, setProjectData).then(completed => ({
          step: 0, 
          completed
        })),
        processSourceData(orgId, id, sourceTypeOptions, setSelectedMainType, setPrevSource, setSourceData).then(res => ({
          step: 1, 
          completed: res.completed, 
          id: res.id
        }))
      ]).then((results) => {
        const newCompleted = {};
        let tempDestId = '';
        let tempSourceId = '';
  
        results.forEach(result => {
          if(product === 'KalVoice'){
            if(result.step !== 0){
              newCompleted[result.step-1] = result.completed;
            }
          }
          else{
            newCompleted[result.step] = result.completed;
          }
          if (result.step === 2) tempDestId = result.id;
          if (result.step === 1) tempSourceId = result.id;
        });
        if(product === 'KalVoice'){
          setIsReady(newCompleted[0]&&newCompleted[1])
        }
        else{
          setIsReady(newCompleted[0]&&newCompleted[1]&&newCompleted[2])
        }
        setInitComplete(newCompleted);
        if (tempDestId) setDestId(tempDestId);
        if (tempSourceId) setSourceId(tempSourceId);
        setInitLoading(false);
      });
    }
    console.log(projectData.project_type)
    }, [clicked]);

    const handleConfirm = () =>{
      handleDelete(id, orgId);
      setConfirm(false);
    }

    useEffect(()=>{
      if(clicked){
        if(product === 'KalVoice'){
          setIsReady(initComplete[0]&&initComplete[1])
        }
        else{
          setIsReady(initComplete[0]&&initComplete[1]&&initComplete[2])
        }
      }
    }, [initComplete])

  
  
  
  return (
    <>
    {confirm && <ConfirmPopup message="Are you sure you want to delete the project? all the data will be lost" onConfirm={handleConfirm} onCancel={(e) => setConfirm(false)}/>}
    {clicked && (initLoading? <LoadingCircle/> : <StepperForm name={label} initcompleted={initComplete} setInitComplete={setInitComplete} sourceId={sourceId} 
                                                  destId={destId} data={data} product={product} workspace_id={id} project_type={projectData.project_type} onClick={(e)=> setClicked(false)}/>)}
    <div className={styles.card}>
            <div className={styles.cardHeader}>{label}</div>
            <hr className={styles.divider} />
            <div className={styles.iconContainer}>
                {/* <img src={icon} alt="Icon" className={styles.icon} /> */}
                {icon === 'docs' && <IoDocumentsOutline className={styles.icon}/>}
                {icon === 'voice' && <BiUserVoice className={styles.icon}/>}
                {icon === 'chat' && <RiWechatLine className={styles.icon}/>}
            </div>
            <button 
                className={`${styles.statusButton} ${isReady ? styles.ready : styles.notReady}`} 
                onClick={goToProject} 
                disabled={!isReady}
            >
                {isReady ? "Go to Project" : "Not Ready"}
            </button>
            <div className={styles.actions}>
                <CiSettings className={styles.actionIcon} onClick={() => setClicked(true)}/>
                {/* <FiEdit2 className={styles.actionIcon2} /> */}
                <MdDeleteOutline className={styles.actionIcon} onClick={(e)=> setConfirm(true)}/>
            </div>
        </div>
    </>
  );
};

export default Workspace;
