import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useSourceService = () => {
  const gateway = useSelector(state => state.data.gateway)

  const scan = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source/scan`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to start scanning"
      }
    }
  };

return { 
    scan,
  };
};

export default useSourceService;