import React , { useState } from 'react';
import styles from "./css/PopupForm.module.css"
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage, setShowToast } from '../../../store/data-slice';
import useWorkspace from '../../api/workspace';
import useProcessProjects from '../../api/processProjects';

function CreateProjectForm({ handleClose, setLoading}){
    const orgId = useSelector((state) => state.user.org_id);
    const processProjectsData = useProcessProjects();
    const [formData, setFormData] = useState({org_id: orgId, name: '', product: '', type: '', language: ''})
    const {create} = useWorkspace();
    const dispatch = useDispatch();


    // TODO take from the products that the org has (maybe global? )
    const products = ['', 'KalDocs', 'KalVoice', 'KalChat']
    const types = ['structured', 'unstructured']

    const handleChange = (event) => {
        const { id, value } = event.target;
        let updatedFormData = {
            ...formData,
            [id]: value,
        };

        if (id === 'product') {
            if (value === 'KalVoice') {
                updatedFormData.type = 'voice';
            } else if (value !== 'KalDocs') { // Reset type if not 'KalDocs' and not 'KalVoice'
                updatedFormData.type = 'chat';
            }
            // No else needed, 'type' selection will be handled by the dropdown for 'KalDocs'
        }
        setFormData(updatedFormData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        // Custom validation logic
        if (formData.name === '' || formData.org_id === '' || formData.product === '' || (formData.product === 'KalDocs' && formData.type === '')) {
            dispatch(setToastMessage('Please fill all the required fields.'));
            dispatch(setShowToast(true));
        } 
        else {
            try{
                setLoading(true);
                await create(formData);
                processProjectsData(orgId);
                setLoading(false);
                dispatch(setToastMessage('project created successfully.'))
                dispatch(setShowToast(true))
                handleClose();
                window.location.reload();
            }
            catch(error){
                setLoading(false);
                dispatch(setToastMessage(error))
                dispatch(setShowToast(true))
            }
        }
    };

    return (
        <div className={`${styles.container} ${styles.modal}`}>
            <div className={`${styles.frame} ${styles.modalFrame}`}>
                <button className={styles.closeButton} onClick={(e) => handleClose()} >&times;</button>
                <div className={styles.form}>
                    <div className={styles.label}>
                    <div className={styles.text_wrapper}> Create New Project </div>
                    </div>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <label htmlFor='name'>Name</label>
                        <input id='name' className={styles.input_field} value={formData.name} onChange={handleChange}></input>

                        <label htmlFor='product'>Product</label>
                        <select id='product' className={styles.select_field} value={formData.product} onChange={handleChange}>
                            {products.map(product => (
                                <option key={product} value={product}>{product}</option>
                            ))}
                        </select>

                        {formData.product === 'KalDocs' && (
                            <>
                                <label htmlFor='type'>Type</label>
                                <select id='type' className={styles.select_field} value={formData.type || ''} onChange={handleChange}>
                                <option value="">Please select</option>
                                    {types.map(type => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </select>
                            </>
                        )}
                        {(formData.product === 'KalVoice' || formData.product === 'KalChat') && (
                            <>
                                <label htmlFor='language'>Language</label>
                                <select id='language' className={styles.select_field} value={formData.language || ''} onChange={handleChange}>
                                <option value="">Please select</option>
                                <option key="1" value="he">he</option>
                                <option key="2" value="en">en</option>
                                </select>
                            </>
                        )}
                        <button type="submit" className={styles.button}>Submit</button>
                    </form>
                </div>
            </div>
            {/* {showToast && <Toast message={message} onClose={() => setShowToast(false)} />} */}
        </div>
    )
}

export default CreateProjectForm;