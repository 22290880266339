import React , { useEffect, useState , useImperativeHandle, forwardRef} from 'react';
import styles from "./css/Form.module.css";
import useSourceMng from "../../api/sourceManager";

function CreateSourceForm({id, typeOptions, prevData, setLoading, onSubmit, formData, setFormData, selectedMainType, setSelectedMainType}, ref){
    const {create, update} = useSourceMng();
    const [isInitialized, setIsInitialized] = useState(false);

    useImperativeHandle(ref, () => ({
        submitForm: (type) => {
            if(type === 'Edit'){
                handleEdit({ preventDefault: () => {} }); 
            }else{
                handleSubmit({ preventDefault: () => {} }); 
            }
        }
    }));

    const handleMainTypeChange = (event) => {
        setSelectedMainType(event.target.value);
        let e = {
            target: {
                id: 'type',
                value: ''
            }
        }

        if (typeOptions[event.target.value]?.length === 0) {
            e.target.value = event.target.value;
        }
        handleChange(e);
    };

    useEffect(()=> {
        //get type options
    }, [])


    useEffect(()=>{
        if(isInitialized || !prevData){
            loadData(formData.type);
        }else{
            setIsInitialized(true);
        }
    }, [formData.type])

    const loadData = (type) => {
        let data = {}
        if (type !== ''){
            data = {
                address: '',
                password: '',
                folder: 'inbox'
            };
        }
    
        setFormData(prevFormData => ({
            ...prevFormData,
            details: {...data} 
        }));
    };

    const handleDetailChange = (key, newValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            details: {
                ...prevFormData.details,
                [key]: newValue
            }
        }));
    };    

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.id]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.name === '' || formData.type === '' ) {
            onSubmit({isValid: false, error: 'please fill all the data'})
        } 
        else {
            try{
                await create(formData)
                onSubmit({isValid: true})
            }
            catch(err){
                onSubmit({isValid: false, error: err})
            }
        }
    };

    const handleEdit = async (event) => {
        event.preventDefault();
        if (formData.name === '' || formData.type === '' ) {
            onSubmit({isValid: false, error: 'please fill all the data'})
        } 
        else {
            try {
                const body = {...formData, id: id, workspace_id: formData.id}
                await update(body)
                onSubmit({isValid: true})
            }
            catch(err){
                onSubmit({isValid: false, error: err})
            }
        }

    }

    return (
        <div className={`${styles.container}`}>
        <div className={`${styles.frame}`}>
            <div className={styles.form}>
                <div className={styles.label}>
                    <div className={styles.text_wrapper}>
                        <span className={styles.optionalIndicator}>{true ? '*' : ''}</span>
                        Create Source Connection
                    </div>
                </div>
                <form className={styles.form}>
                    <div className={styles.processor}>
                        <label htmlFor='name'>connection Name</label>
                        <input id='name' className={styles.input_field} value={formData.name} onChange={handleChange}></input>

                        <label htmlFor='mainType'>connection Type</label>
                        <select id='mainType' className={styles.select_field} value={selectedMainType} onChange={handleMainTypeChange}>
                            <option value="">select Type</option>
                            {Object.keys(typeOptions).map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>
                        {selectedMainType && typeOptions[selectedMainType].length > 0 && (
                            <select id="type" className={styles.select_field} value={formData.type} onChange={handleChange}>
                                <option value="">select {selectedMainType} type</option>
                                {typeOptions[selectedMainType].map((subtype, index) => (
                                    <option key={index} value={subtype}>{subtype}</option>
                                ))}
                            </select>
                        )}
                        {Object.entries(formData.details).map(([key, value], index) => (
                            <React.Fragment key={index}>
                                <label htmlFor={key}>{key}:</label>
                                <input
                                    className={styles.input_field}
                                    id={key}
                                    type={key === 'password' ? 'password' : 'text'}
                                    value={value}
                                    onChange={(e) => handleDetailChange(key, e.target.value)}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default forwardRef(CreateSourceForm);