import React, { useState, useRef, useEffect } from 'react';
import styles from './css/SearchInput.module.css';
import searchIcon from './icons/search.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setdocsProjectsToShow, setFilteredSuggestionEmpty } from '../store/data-slice'
import useClickOutside from './useClickOutside'



const SearchInput = ({ placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const docsProjects = useSelector(state => state.data.docsProjects);
  const mediaProjects = useSelector(state => state.data.mediaProjects);
  const voiceProjects = useSelector(state => state.data.voiceProjects);
  const sideBarNavState = useSelector(state => state.data.sideBarNavState);


  const [allProjects, setAllProjects] = useState([...docsProjects, ...mediaProjects, ...voiceProjects]);
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  useEffect(() => {
    switch (sideBarNavState) {
      case 'home':
        setAllProjects([...docsProjects, ...mediaProjects, ...voiceProjects]);
        break;
      case 'kaldocs':
        setAllProjects([...docsProjects]);
        break;
      case 'kalvoice':
        setAllProjects([...voiceProjects]);
        break;
      case 'kalchat':
        setAllProjects([...mediaProjects]);
        break;
    }
  }, [docsProjects, mediaProjects, voiceProjects, sideBarNavState]);


  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if(value === '')
    {
      const projectsToShow  = allProjects.map(project => ({ name: project.name, id: project._id }));
      dispatch(setdocsProjectsToShow(projectsToShow));
      setSuggestions([]);
      dispatch(setFilteredSuggestionEmpty(false));
      return;
    }
    if (value) {
      const filteredSuggestions = allProjects.filter(project =>
        project.name.toLowerCase().startsWith(value.toLowerCase())
      ).map((project) => ({ name: project.name, id: project._id }));
      dispatch(setdocsProjectsToShow(filteredSuggestions));
      setSuggestions(filteredSuggestions);
      dispatch(setFilteredSuggestionEmpty(true))

    } else {
      dispatch(setFilteredSuggestionEmpty(false))
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestionId) => {
    console.log('Clicked on suggestion with ID:', suggestionId);
  };

  useClickOutside(searchRef, () => setSuggestions([]));

  return (
    <label ref={searchRef} className={styles.searchLabel}>
      <img src={searchIcon} alt="Search" className={styles.searchIcon} />
      <input
        type="text"
        placeholder={placeholder}
        className={styles.searchInput}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {/* {suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map(suggestion => (
            <
              li key={suggestion._id} 
              className={styles.suggestionItem}
              onClick={() => handleSuggestionClick(suggestion.id)}
              >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )} */}
    </label>
    
  );
};

export default SearchInput;
