import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useDestMng = () => {
  const gateway = useSelector(state => state.data.gateway)

const create = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/dest_mng/create`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to create new destination"
    }
  }
};

const deleteDestConn = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/dest_mng/delete`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to delete destination"
    }
  }
};

const get = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/dest_mng/get`, 'POST', body);
    return response;
  } catch (err) {
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to get destination"
    }
  }
};

const update = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/dest_mng/update`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to update destination"
    }
  }
};

const getAll = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/dest_mng/get_all`, 'POST', body);
    return response;
  } catch (err) {
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to get all destinations"
    }
  }
};

const getProjectDest = async (body) => {
  try{
    const response = await makeRequest(`${gateway}/api/dest_mng/get_workspace_dest`, 'POST', body)
    return response
  }
  catch(err){
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to get destination"
    }
  }
};

  return { 
    create,
    deleteDestConn,
    get,
    update, 
    getAll, 
    getProjectDest
  };
};

export default useDestMng;
