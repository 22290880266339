import React, { useState, useRef, useEffect } from 'react';
import styles from './css/SearchInput.module.css';
import searchIcon from './icons/search.svg';
import { useSelector, useDispatch } from 'react-redux';
import useClickOutside from './useClickOutside'


const SearchInput = ({ placeholder, setRowData , allDocuments}) => {
    const searchRef = useRef(null);

    
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value) {
            const filteredSuggestions = allDocuments.filter(document =>
                document.name.toLowerCase().startsWith(value.toLowerCase())
            ).map((document) => ({ name: document.name ,result: document.result, key: document.key , url: document.url}));
            setSuggestions(filteredSuggestions);      
          } else {
            setSuggestions([]);
          }

    }

    const handleSuggestionClick = (suggestion) => {
        setRowData(suggestion)
        setSuggestions([]);
      };

    useClickOutside(searchRef, () => setSuggestions([]));

    return (
        <label ref={searchRef}  className={styles.searchLabel}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
          <input
            type="text"
            placeholder={placeholder}
            className={styles.searchInput}
            value={searchTerm}
            onChange={handleInputChange}
          />
          {suggestions.length > 0 && (
            <ul className={styles.suggestionsList}>
              {suggestions.map(suggestion => (
                <
                  li key={suggestion.id} 
                  className={styles.suggestionItem}
                  onClick={() => handleSuggestionClick(suggestion)}
                  >
                  {suggestion.name}
                </li>
              ))}
            </ul>
          )}
        </label>
        
      );

}


export default SearchInput;
