import useDestMng from './DestinationsManager';
import useSourceMng from './sourceManager';
import useProductMng from './productManager';

const useProcessProject = () => {
    const {getProjectDest} = useDestMng();
    const {getAll} = useSourceMng();
    const {getWorkspaceProduct} = useProductMng();

    const processProjectsDestData = async (orgId, projectId, setPrevDest, setDestData) => {
        const body = {id: projectId, org_id: orgId}
        let completed = false;
        let id = ''
        try {
            const response = await getProjectDest(body)
            if(response){
            setPrevDest(true);
            setDestData(prev => ({
                ...prev,
                name: response.name, type: response.type, attributes: response.attributes
            }))
            completed = true
            id = response._id
            }
        } catch (err) {
            console.error(err);
        }
        return {completed: completed, id: id};
    };

    const processProjectProduct = async (orgId, projectId, setPrevProduct, setProjectData) => {
        let productBody = {workspace_id: projectId, org_id: orgId}
        let completed = false;
        try{
            const response = await getWorkspaceProduct(productBody)
            if(response){
                console.log(response)
                setPrevProduct(true);
                setProjectData(prev => ({
                    ...prev,
                    processors: response.processors
                }))
                completed = true
                console.log(response)
            }
        }
        catch(err){
            console.log(err)
        }
        return completed;
    }

    const processSourceData = async (orgId, projectId, sourceTypeOptions, setSelectedMainType, setPrevSource, setSourceData) => {
        const body = {id: projectId, org_id: orgId}
        let completed = false;
        let id = ''
        try{
            let response = await getAll(body)
            if(response.length > 0){
                completed = true;
                response = response[0]
                for (const option of Object.keys(sourceTypeOptions)) {
                    if (Array.isArray(sourceTypeOptions[option])) {
                        if (sourceTypeOptions[option].includes(response.type)) {
                            setSelectedMainType(option);
                            break; 
                        }
                    } else {
                        if (option === response.type) {
                            setSelectedMainType(option);
                            break; 
                        }
                    }
                }
                setPrevSource(true);
                setSourceData(prev => ({
                    ...prev,
                    name: response.name, type: response.type, details: response.details
                }))
                id = response._id
            }
        }catch(err){
            console.log(err)
        }
        return {completed: completed, id: id};
    }

    return {processProjectsDestData, processProjectProduct, processSourceData};

};

export default useProcessProject;
