import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import styles from "./css/DocumentView.module.css"

const PdfViewer = ({ pdfUrl }) => {
  // Create a new instance of the defaultLayoutPlugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className={styles.pdfViewer}>
    <Worker workerUrl={pdfjsWorker}>
      <Viewer
        fileUrl={pdfUrl}
        plugins={[defaultLayoutPluginInstance]}
      />
      </Worker>
    </div>
  );
};
export default PdfViewer;