import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from "../Forms/css/PopupForm.module.css";
import Toast from "../../../tools/Toast";
import { useNavigate } from 'react-router-dom';

export function IotSignup({ product, type }) {

    const [jwt, setJwt] = useState('');

    useEffect(() => {
        console.log('before token: '+jwt)
        const token = new URLSearchParams(window.location.search).get('token')
        if (token) {
            console.log('token: '+jwt)
            setJwt(token);
        }
    }, []);

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        organization_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        product_type: type,
        product: product,
        vm_service_account: '',
        artifact_service_account: '',
    });

    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let isFormValid = true;
        let errorMessage = '';

        // Updated required fields
        const requiredFields = ['organization_name', 'first_name', 'last_name', 'email', 'phone', 'vm_service_account', 'artifact_service_account'];
        for (const field of requiredFields) {
            if (!formData[field]) {
                isFormValid = false;
                errorMessage = 'Please fill all required fields.';
                break;
            }
        }

        if (!isFormValid) {
            setMessage(errorMessage);
            setShowToast(true);
            return;
        }

        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            if (jwt) {
                headers['Authorization'] = `Bearer ${jwt}`;
            }

            const response = await fetch('https://kal-studio.marketplace.kaleidoo-dev.com/create_account', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                console.log(`response: ${response}`)
                setMessage(response.message || 'Operation failed.');
                setShowToast(true);
            } else {
                setMessage(response.message || 'Operation successful!');
                setShowToast(true);
                navigate('/signup-welcome-page', { state: { productType: "Not SaaS" } });
            }
        } catch (error) {
            console.error('Operation failed:', error);
            setMessage(error.message || 'Operation failed.');
            setShowToast(true);
        }
    };

    return (
        <div className={`${styles.container} ${styles.modal}`}>
            <div className={`${styles.frame} ${styles.modalFrame}`}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.label}>Sign Up for {product}</div>
                    <input name='organization_name' placeholder='Organization Name' value={formData.organization_name} onChange={handleChange} />
                    <input name='first_name' placeholder='First Name' value={formData.first_name} onChange={handleChange} />
                    <input name='last_name' placeholder='Last Name' value={formData.last_name} onChange={handleChange} />
                    <input name='email' placeholder='Email' value={formData.email} onChange={handleChange} />
                    <input name='phone' placeholder='Phone' value={formData.phone} onChange={handleChange} />
                    <input name='vm_service_account' placeholder='VM Service Account (from GCS project in folder)' value={formData.vm_service_account} onChange={handleChange} />
                    <input name='artifact_service_account' placeholder='Artifact Service Account (from GCS project in folder)' value={formData.artifact_service_account} onChange={handleChange} />
                    <button type="submit">Submit</button>
                </form>
            </div>
            {showToast && <Toast message={message} onClose={() => setShowToast(false)} />}
        </div>
    );
}
