import React, { useState , useEffect} from 'react';
import { Avatar, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './css/Navbar.module.css';
import SearchInput from './SearchInput';
import DocsSearchInput from './DocsSearchInput';
import { logout } from '../store/user-slice';
import { resetData } from '../store/data-slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const NavBar = ({ showSearch, showPopup, text, setRowData, title, allDocuments }) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
  const [logoError, setLogoError] = useState(false);
  const userName = useSelector(state => state.user.user_name);
  const orgName = useSelector(state => state.user.org_name);
  const role = useSelector((state) => state.user.role);
  const logo = useSelector(state => state.user.org_logo);
  const open = Boolean(userMenuAnchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleAddMenu = (event) => {
    if(title === 'KalSense'){
      if(role === 'admin'){
        setAddMenuAnchorEl(event.currentTarget);
      }
      else{
        showPopup('project')
      }
    }
  };

  const handleCloseAddMenu = (type) => {
    if(type !== ''){
      showPopup(type)
    }
    setAddMenuAnchorEl(null); // And here
  };

  useEffect(() => {
    const image = new Image();
    image.src = logo;
    image.onload = () => setLogoError(false);
    image.onerror = () => setLogoError(true);
  }, [logo]);

  const handleLogout = () => {
    dispatch(resetData());
    dispatch(logout());
    navigate('/');
  };

  const titleColorMapping = {
    'KalSense': '#193855', 
    'KalDocs': '#4C8CB5', 
    'KalAudio': '#667292', 
    'KalChat': '#9370DB',
    'defaultColor': '#193855',
  };

  const backgroundColor = titleColorMapping[title] || titleColorMapping['defaultColor'];
  const navBarStyle = {
    backgroundColor: backgroundColor,
  };

  return (
    <header className={styles.header} style={navBarStyle}>
      <div className={styles.logo}>{title}</div>
      {logoError && <div className={styles.org_name}>{orgName}</div>}
      <div className={styles.searchContainer}>
        {(showSearch && text.toLowerCase().includes("project")) && <SearchInput placeholder={text} />}
        {(showSearch && text.toLowerCase().includes("document")) && <DocsSearchInput allDocuments={allDocuments} placeholder={text} setRowData={setRowData} />}
      </div>
      {showPopup && (
        <>
          <Typography sx={{marginRight: 2}}>Hello {userName}</Typography>
        <div className={styles.userContainer}>
            <IconButton onClick={handleUserMenu} size="large" sx={{ p: 0 }}>
              <Avatar src="/path/to/user/image.jpg" />
            </IconButton>
          <Menu
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>Profile</MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          {!logoError && <img className={styles.logo_image} src={logo} alt='org logo'></img>}
        </div>
          <IconButton className={styles.addButton} onClick={handleAddMenu} >
          <AddIcon />
        </IconButton>
          <Menu
        anchorEl={addMenuAnchorEl} 
        open={Boolean(addMenuAnchorEl)}
        onClose={(e) => handleCloseAddMenu('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem onClick={(e) => handleCloseAddMenu('user')}>Add User</MenuItem>
        <MenuItem onClick={(e) => handleCloseAddMenu('project')}>Add Project</MenuItem>
      </Menu>
        </>
      )}
    </header>
  );
};

export default NavBar;
