import React from 'react';
import styles from './css/LoadingCircle.module.css';  // Assuming you will create a separate CSS file

const LoadingCircle = () => {
    return (
        <div className={styles.overlay}>
            <div className={styles.loading_circle}></div>
        </div>
    );
};

export default LoadingCircle;
