import React from 'react';
import KalchatProjectPage from '../../../kalchat/components/Pages/ProjectPage';
import KaldocsProjectPage from '../../../kaldocs/components/Pages/ProjectPage';
import KalvoiceProjectPage from '../../../kalvoice/components/Pages/ProjectPage';
import { useLocation } from 'react-router-dom';

function Page(){
    const location = useLocation();
    const projectParams = location.state;

    return (
        <>
        {projectParams.product === "KalDocs" && <KaldocsProjectPage projectParams={projectParams}/>}
        {projectParams.product === "KalChat" && <KalchatProjectPage projectParams={projectParams}/>}
        {projectParams.product === "KalVoice" && <KalvoiceProjectPage projectParams={projectParams}/>}
        </>
    )

}

export default Page;