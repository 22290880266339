import React from 'react';
import styles from './css/Sidebar.module.css';
// icons
import { BiLogOutCircle } from "react-icons/bi";
// import { IoHomeOutline } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { HiMiniInboxArrowDown } from "react-icons/hi2";
import needApproved from "../../tools/icons/needApproved.svg"
import approved from "../../tools/icons/approved.svg"
import notApproved from "../../tools/icons/notApproved.svg"
import { IoReturnDownBackOutline } from "react-icons/io5";
// import newDocument from "../tools/icons/newDocument.svg"
import { useNavigate } from 'react-router-dom';


const Sidebar = ({inboxLength, needApprovedLength, approvedLength, notApprovedLength, setCurrentTab, currentTab}) => {

  const navigate = useNavigate();


  const handleKalStudioClick = () => {
    navigate('/projects');
  };

  const kaldocsMenu = [
    // {name: 'New Document', icon: 'new', count: null},
    // { name: 'Home', icon: 'home', count: null, onClick: () => setCurrentTab('Home')},
    { name: 'Inbox', icon: 'inbox', count: inboxLength, onClick: () => setCurrentTab('Inbox')},
    { name: 'Need Approved', icon: 'need approved', count: needApprovedLength, onClick: () => setCurrentTab('Need Approved')},
    { name: 'Approved', icon: 'approved', count: approvedLength, onClick: () => setCurrentTab('Approved')},
    { name: 'Not Approved', icon: 'not approved', count: notApprovedLength, onClick: () => setCurrentTab('Not Approved')},
    { name: 'Return to KalSense', icon: 'returnHome', count: null, onClick: handleKalStudioClick},
  ]

  
  return (
    <aside className={styles.sidebar}>
      {kaldocsMenu.map(item => (
        <div 
          key={item.name} 
          className={`${styles.menuItem} ${item.icon === 'new' ? styles.newDocument : ''} ${currentTab === item.name ? styles.clicked : ''}`} 
          onClick={() => {
            item.onClick();
          }} 
        >
        {item.icon === 'inbox' && <HiMiniInboxArrowDown className={styles.icon}/>}
        {item.icon === 'need approved' && <img src={needApproved} alt={`${item.name} icon`} className={styles.icon} />}
        {/* {item.icon === 'new' && <img src={newDocument} alt={`${item.name} icon`} className={styles.icon} />} */}
        {item.icon === 'not approved' && <img src={notApproved} alt={`${item.name} icon`} className={styles.icon} />}
        {item.icon === 'approved' && <img src={approved} alt={`${item.name} icon`} className={styles.icon} />}
        {item.icon === 'logout' && <BiLogOutCircle className={styles.icon}/>}
        {item.icon === 'home' && <GoHomeFill className={styles.icon}/>}
        {item.icon === "returnHome" && <IoReturnDownBackOutline className={styles.icon}/>}
        <span className={styles.menuText}>{item.name}</span>
        {item.count !== null && <span className={styles.badge}>{item.count}</span>}
        </div>
      ))}
    </aside>
  );
};


export default Sidebar;
