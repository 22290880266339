import React from 'react';
import PdfViewer from './Pdfview';
import ResultView from './ResultView';
import styles from './css/DocumentView.module.css'; 
import { useSelector } from 'react-redux';
import useFileMng from '../../../kalstudio/api/fileManager';

const DocumentView = ({ refresh, project_id, rowData ,onBack, activeTab }) => {
    const {update} = useFileMng();
    const orgId = useSelector((state) => state.user.org_id);
    let documentBody = {
        org_id: orgId,
        id: project_id,
        key: rowData.key
    }
    
    const handleApproved = async () => {
        documentBody.status = 'approved'
        try{
            await update(documentBody);
            refresh()
        }
        catch(err){

        }
    }

    const handleNotApproved = async () => {
        documentBody.status = 'not_approved'
        try{
            await update(documentBody);
            refresh()
        }
        catch(err){

        }
    }

  return (
    <>
        <div className={styles.miniNav}>
            <button onClick={onBack}>Back to {activeTab}</button>
            {activeTab !== 'Inbox' && 
            <div className={styles.rightButtons}>
                <button className={styles.approved} onClick={handleApproved}>Approved</button>
                <button className={styles.not_approved} onClick={handleNotApproved}>Not Approved</button>
            </div>}
      </div>
      <div className={styles.documentView}>
        <PdfViewer pdfUrl={rowData.url} />
        <ResultView jsonData={rowData.result} />
    </div>
    </>
  );
};

export default DocumentView;
