import React from 'react';
import './App.css';
import { ProtectedSuperAdminRoute, ProtectedUsersRoute } from './ProtectedRoute';
import SuperAdminPage from './kalstudio/components/Pages/SuperAdminPage';
import LoginPage from './kalstudio/components/Pages/LoginPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProjectsPage from './kalstudio/components/Pages/ProjectsPage';
import Page from './kalstudio/components/Pages/Page';
import "@fontsource/poppins";
import Toast from './tools/Toast';
import { useSelector } from 'react-redux';
import { SaaStudioSignup } from './kalstudio/components/MarketPlace/SaaStudioSignup'
import { NStudioSignup } from './kalstudio/components/MarketPlace/NStudioSignup'
import { KalMediaSignup } from './kalstudio/components/MarketPlace/KalMediaSignup'
import { IotSignup } from './kalstudio/components/MarketPlace/IotSignup'
import { MediaLogin } from './kalstudio/components/Pages/MediaLogin'
import { IotLogin } from './kalstudio/components/Pages/ItoLogin'
import { ProductMessage } from './kalstudio/components/MarketPlace/ProductMessage'
import SupportPage from './kalstudio/components/Pages/SupportPage';



function App() {
  const message = useSelector((state) => state.data.toastMessage);
  const showToast = useSelector((state) => state.data.showToast);
  return (


    <div className="App">
      <Router>
        <Routes>
        /* Login Pages */
          <Route path="/support" element={<SupportPage/>}/>
          <Route path="/" element={<LoginPage/>}></Route>
          <Route path="/kal-media-login" element={<MediaLogin/>}></Route>
          <Route path="/kal-iot-login" element={<IotLogin/>}></Route>
          <Route path="/signup-welcome-page" element={<ProductMessage/>}></Route>
          /* Marketplace Saas Signup Routes */
          <Route path="/ss-docs-signup" element={<SaaStudioSignup product={"KalDocs"} type={"ss"}/>}></Route>
          <Route path="/ss-voice-signup" element={<SaaStudioSignup product={"KalVoice"} type={"ss"}/>}></Route>
          <Route path="/ss-chat-signup" element={<SaaStudioSignup product={"KalChat"} type={"ss"}/>}></Route>
          <Route path="/ss-media-signup" element={<KalMediaSignup product={"KalMedia"} type={"ns"}/>}></Route>
          <Route path="/ss-iot-signup" element={<IotSignup product={"IOT"} type={"ns"}/>}></Route>
          /* Marketplace Not Saas Signup Routes */
          <Route path="/ns-docs-signup" element={<NStudioSignup product={"KalDocs"} type={"ns"}/>}></Route>
          <Route path="/ns-voice-signup" element={<NStudioSignup product={"KalVoice"} type={"ns"}/>}></Route>
          <Route path="/ns-chat-signup" element={<NStudioSignup product={"KalChat"} type={"ns"}/>}></Route>
          <Route path="/ns-media-signup" element={<KalMediaSignup product={"KalMedia"} type={"ns"}/>}></Route>
          <Route path="/ns-iot-signup" element={<IotSignup product={"IOT"} type={"ns"}/>}></Route>
          /* Protected Routes */
          <Route element={<ProtectedSuperAdminRoute/>}>
            <Route path="/super-admin" element={<SuperAdminPage/>}></Route>
          </Route>
          <Route element={<ProtectedUsersRoute/>}>
            <Route path='/projects' element={<ProjectsPage/>}></Route>
            <Route path="/project/:type/:name" element={<Page/>}/>
          </Route>
        </Routes>
      </Router>
     {showToast && <Toast message={message} />}
    </div>
  );
}

export default App;
