import React from 'react';
import { Tabs } from 'antd';
import styles from './css/DocumentView.module.css';
import resultStyles from "./css/ResultView.module.css";

const { TabPane } = Tabs;

const TextViewer = ({ textContent }) => {
  return (
    <div className={styles.resultView}>
      <Tabs defaultActiveKey="1" animated>
        <TabPane tab="Text Content" key="1">
          <div className={resultStyles.tabContent}>
            <h3 className={resultStyles.sectionTitle}>Text File</h3>
            <div className={`${resultStyles.textContentView} ${resultStyles.rightToLeftText}`}>
              {/* Display the text content here */}
              <pre>{textContent}</pre>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Note" key="2">
          <div className={resultStyles.tabContent}>Notes content goes here...</div>
        </TabPane>
        <TabPane tab="History" key="3">
          <div className={resultStyles.tabContent}>History content goes here...</div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TextViewer;
