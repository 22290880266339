import React ,{useState} from 'react';
import { Space, Table, Tag, Pagination } from 'antd';
import useFileMng from '../../kalstudio/api/fileManager';
import "./css/Table.css"
import LoadingCircle from '../../tools/LoadingCircle';

const { Column } = Table;

const DataTable = ({org_id, project_id, data , setShowView, setRowData}) => {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const {updateUrl} = useFileMng();
    
    const fileSizeToBytes = (size) => {
        const units = {
          kb: 1024,
          mb: 1024 * 1024,
          gb: 1024 * 1024 * 1024
        };
      
        const regex = /(\d+(?:\.\d+)?)\s*(kb|mb|gb)/i;
        const matches = size.toLowerCase().match(regex);
        if (matches) {
          const value = parseFloat(matches[1]);
          const unit = matches[2];
          return value * (units[unit] || 1);
        }
        return 0;
    };

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: event => {
                const handleClick = async () => {
                    setLoading(true);
                    const body = {
                        org_id: org_id,
                        id: project_id,
                        file_key: record.key,
                    };
                    try {
                        const response = await updateUrl(body);
                        if(response.Message){
                            setShowView(true);
                            setRowData(record);
                        }else {
                            setShowView(true);
                            setRowData(response);
                        }
                    } catch (err) {
                        console.error(err);
                        setShowView(true);
                        setRowData(record);
                    }
                    finally {
                        setLoading(false);
                    }
                };
                handleClick();
            },
        };
    };
    

    const handleTableChange = (pagination) => {
        // Update the current page and page size
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <div className="tableContainer">
            <Table className={'documents_table'} dataSource={data} onRow={onRowClick} pagination={false}>
                <Column 
                    title="File Name" 
                    dataIndex="name" 
                    key="name"
                    // filters={fileNameFilters}  // Add this line
                    onFilter={(value, record) => record.name.includes(value)}
                    sorter={(a, b) => a.name.localeCompare(b.name)} 
                    width={300}
                />
                <Column 
                    title="Date" 
                    dataIndex="date" 
                    key="date" 
                    sorter={(a, b) => a.age - b.age} 
                    width={200}
                />
                <Column 
                    title="File Size" 
                    dataIndex="size" 
                    key="size" 
                    sorter={(a, b) => fileSizeToBytes(a.size) - fileSizeToBytes(b.size)} 
                    width={200}
                />
                <Column
                    title="File Type"
                    dataIndex="tags"
                    key="tags"
                    render={(tags) => (
                        <>
                            {tags.map(tag => (
                                <Tag color="blue" key={tag}>
                                    {tag}
                                </Tag>
                            ))}
                        </>
                    )}
                    width={200}
                />
                {/* <Column
                    title="Description"
                    dataIndex="key" // Assuming each row data has a unique 'key'
                    render={(text, record) => (
                        <input 
                            type="text" 
                            value={descriptions[record.key] || ''} 
                            onChange={(e) => handleDescriptionChange(record.key, e.target.value)} 
                            className="editable-text-field"
                        />
                    )}
                    width={200}
                /> */}
            </Table>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data.length}
                onChange={handleTableChange}
                // showSizeChanger
                // pageSizeOptions={['10', '20', '30', '50']}
                style={{ marginTop: '20px' }}
            />
             {loading && <LoadingCircle/>}
        </div>
    )
};

export default DataTable;