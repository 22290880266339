import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useWorkspace = () => {
  const gateway = useSelector(state => state.data.gateway)

const create = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/workspace_mng/create`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create project"
      }
  }
};

const deleteWorkspace = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/workspace_mng/delete`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to delete project"
      }
  }
};

const get = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/workspace_mng/get`, 'POST', body);
    return response;
  } catch (err) {
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get project"
      }
  }
};

const getAll = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/workspace_mng/get_all`, 'POST', body);
    return response;
  } catch (err) {
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get all projects"
      } 
  }
};

  return { 
    create,
    deleteWorkspace,
    get, 
    getAll, 
  };
};

export default useWorkspace;
