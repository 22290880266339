import React, {useRef, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ErrorIcon from '@mui/icons-material/Error';
import StepLabel from '@mui/material/StepLabel';
import styles from "./css/StepperForm.module.css"
import CreateProductDataForm from './CreateProductDataForm';
import CreateSourceForm from './CreateSourceForm';
import CreateDestForm from './CreateDestForm';
import CircularProgress from '@mui/material/CircularProgress';

export default function StepperForm({name, setInitComplete, onClick, product, initcompleted, data, destId, sourceId, project_type}) {
  const formRef = useRef();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(initcompleted);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [modalAnimation, setModalAnimation] = useState('slideIn');

  const initialSteps = product === 'KalVoice' ? [
    { label: 'source', isOptional: false, hasError: false, error: '' },
    { label: 'destination', isOptional: false, hasError: false, error: '' }
  ] : [
    { label: 'product data', isOptional: false, hasError: false, error: '' },
    { label: 'source', isOptional: false, hasError: false, error: '' },
    { label: 'destination', isOptional: false, hasError: false, error: '' }
  ];

    const [steps, setSteps] = useState(initialSteps);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          0
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = async () => {
    if (formRef.current) {
      setIsSubmitting(true); 
      let buttonText;
      if (completed[activeStep]) {
        buttonText = 'Edit';
    } else if (completedSteps() === totalSteps() - 1) {
        buttonText = 'Finish';
    } else {
        buttonText = 'Complete Step';
    }
      await formRef.current.submitForm(buttonText); 
    }
  };
  
  


  const handleFormValidation = (data) => {
    if (data.isValid) {
        const newCompleted = { ...completed, [activeStep]: true };
        setCompleted(newCompleted);
        setInitComplete(newCompleted);
        const newSteps = steps.map((step, index) => 
            index === activeStep ? { ...step, hasError: false , error: ''} : step
        );
        setSteps(newSteps);
        handleNext(); 
    } else {
        const newSteps = steps.map((step, index) => 
            index === activeStep ? { ...step, hasError: true , error: data.error} : step
        );
        setSteps(newSteps); 
    }
    setIsSubmitting(false);
 };

 const closeModal = () => {
  setModalAnimation('slideOut');
  setTimeout(() => {
    setIsModalVisible(false);
    onClick(); 
  }, 500);
 };

  return (
    <Modal
    open={isModalVisible}
    onClose={closeModal}
    aria-labelledby="stepper-modal-title"
    aria-describedby="stepper-modal-description"
  >
    <Box className={`${styles.stepper_modal} ${styles[modalAnimation]}`}>
      <div className={styles.closeButtonContainer}>
        <button className={styles.closeButton} onClick={closeModal}>&times;</button>
      </div>
      <Stepper className={styles.stepperContainer} nonLinear activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={step.label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel
                StepIconProps={{
                    icon: step.hasError ? <ErrorIcon className={activeStep === index ? styles.errorIcon : styles.inactiveErrorIcon} /> : index + 1
                }}
                >
                {step.label}
                </StepLabel>
            </StepButton>
        </Step>
      ))}
      </Stepper>
      <div className={styles.stepperFormContainer}>
            <>
            <Typography className={styles.errorMessage}>
                {steps[activeStep].error}
            </Typography>
            <div className={styles.inlineContainer}>
              {steps[activeStep].label === 'product data' && <CreateProductDataForm project_type={project_type} prevData={data.prevProduct} product={product} formData={data.projectData} setFormData={data.setProjectData} ref={formRef} onSubmit={handleFormValidation}/>}
              {steps[activeStep].label === 'source' && <CreateSourceForm id={sourceId} typeOptions={data.sourceTypeOptions} selectedMainType={data.selectedMainType} setSelectedMainType={data.setSelectedMainType} prevData={data.prevSource} formData={data.sourceData} setFormData={data.setSourceData} ref={formRef} onSubmit={handleFormValidation}/>}
              {steps[activeStep].label === 'destination' && <CreateDestForm  id={destId} prevData={data.prevDest} formData={data.destData} setFormData={data.setDestData} ref={formRef} onSubmit={handleFormValidation}/>}
            </div>
            </>
      </div>
      <div className={styles.controlButtons}>
        <div className={styles.backButtonContainer}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack}>Back</Button>
        </div>
        <Button onClick={handleNext}>Next</Button>
        <Button onClick={handleComplete} disabled={isSubmitting}>
          {completed[activeStep] ? 'Edit' : (completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step')}
          {isSubmitting && <CircularProgress size={24} className={styles.buttonProgress} />}
        </Button>

        </div>
    </Box>
  </Modal>
  );
}
