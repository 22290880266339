import React, { useState, useEffect } from 'react';
import styles from './css/Sidebar.module.css';
import { IoDocumentsOutline } from "react-icons/io5";
import { BiUserVoice } from "react-icons/bi";
import { RiWechatLine } from "react-icons/ri";
import { GoHomeFill } from "react-icons/go";
import { setdocsProjectsToShow, setSideBarNavState } from '../store/data-slice'
import { useDispatch, useSelector } from 'react-redux';



const Sidebar = ({docsProjectsLen, mediaProjectsLen, voiceProjectsLen}) => {
  const docsProjects = useSelector(state => state.data.docsProjects);
  const mediaProjects = useSelector(state => state.data.mediaProjects);
  const voiceProjects = useSelector(state => state.data.voiceProjects);

  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState('Home');

  useEffect(() => {
    handleNavigation('home'); 
  }, [docsProjects, mediaProjects, voiceProjects]); 

  const handleNavigation = (path) => {
    let projectsToShow = [];
    switch (path) {
      case 'home':
        projectsToShow = [...docsProjects, ...mediaProjects, ...voiceProjects]
        .map(project => ({ name: project.name, id: project._id }));
        break;
      case 'kaldocs':
        projectsToShow = docsProjects.map(project => ({ name: project.name, id: project._id }));
        break;
      case 'kalvoice':
        projectsToShow = voiceProjects.map(project => ({ name: project.name, id: project._id }));
        break;
      case 'kalchat':
        projectsToShow = mediaProjects.map(project => ({ name: project.name, id: project._id }));
        break;
    }
    dispatch(setdocsProjectsToShow(projectsToShow));
    dispatch(setSideBarNavState(path));
    setSelectedItem(path);
  };

  const menuItems = [
    { name: 'Home', icon: 'home', count: null, onClick: () => handleNavigation('home') },
    { name: 'KalDocs', icon: 'docs', count: docsProjectsLen, onClick: () => handleNavigation('kaldocs') },
    { name: 'KalAudio', icon: 'voice', count: voiceProjectsLen, onClick: () => handleNavigation('kalvoice') },
    { name: 'KalChat', icon: 'chat', count: mediaProjectsLen, onClick: () => handleNavigation('kalchat') },
  ];

  return (
    <aside className={styles.sidebar}>
      {menuItems.map(item => (
        <div 
          key={item.name} 
          className={`
            ${styles.menuItem} 
            ${item.icon === 'home' ? styles.main_home : ''} 
            ${selectedItem === item.name.toLowerCase() ? styles.selectedItem : ''}` 
          } 
          onClick={() => {
            item.onClick();
            setSelectedItem(item.name.toLowerCase());
          }} 
        >
        {/* <img src={item.icon} alt={`${item.name} icon`} className={styles.icon} /> */}
        {item.icon === 'docs' && <IoDocumentsOutline className={styles.icon}/>}
        {item.icon === 'voice' && <BiUserVoice className={styles.icon}/>}
        {item.icon === 'chat' && <RiWechatLine className={styles.icon}/>}
        {item.icon === 'home' && <GoHomeFill className={styles.icon}/>}
        <span className={styles.menuText}>{item.name}</span>
        {item.count !== null && <span className={styles.badge}>{item.count}</span>}
        </div>
      ))}
    </aside>
  );
};


export default Sidebar;
