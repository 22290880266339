import React, { useEffect, useState } from 'react';
import styles from './css/Toast.module.css';
import { setShowToast } from '../store/data-slice';
import { useDispatch } from 'react-redux';

function Toast({ message, duration = 3000 }) {
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsVisible(true);
        const timer = setTimeout(() => {
            dispatch(setShowToast(false));
        }, duration);

        return () => clearTimeout(timer); // Clean up the timeout
    }, [message, duration]);

    return (
        <div className={`${styles.toast} ${isVisible ? styles.fadeIn : styles.fadeOut}`}>
            {message}
        </div>
    );
}

export default Toast;
