import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useUser = () => {
  const gateway = useSelector(state => state.data.gateway)

  const create = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/create`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create new user"
      }
    }
  };

  const update = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/update`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to update user"
      }
    }
  };

  const deleteUser = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/delete`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to delete user"
      }
    }
  };

  const get = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/get`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get user"
      }
    }
  };

  const get_by_fi = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/get_by_fi`, 'POST', body);
      return response;
    } catch (err) {
      console.log("err: " + err)
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get user"
      } 
    }
  };

  const getAll = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/user_mng/get_all`, 'POST', body);
      return response.data;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get all users"
      }
    }
  };

  return { 
    create,
    update, 
    deleteUser,
    get, 
    getAll, 
    get_by_fi
  };
};

export default useUser;
