import React from 'react';
import styles from "./css/Form.module.css"

function KalDocsForm({processors, setProcessors, project_type}){

    const initialProcessor = { name: '', type: 'extractor', url: '' };

    const addProcessor = () => {
        if (project_type === 'structured') {
            setProcessors([...processors, { ...initialProcessor, type: 'extractor' }]);
        } else if (project_type === 'unstructured') {
            const classifierExists = processors.some(processor => processor.type === 'classifier');
        
            if (!classifierExists) {
                setProcessors([...processors, { ...initialProcessor, type: 'classifier' }]);
            } else {
                console.log("A classifier already exists. No more classifiers can be added.");
            }
        } else {
            // Handle other project_types if there are any
        }
    };
    

    const removeProcessor = (index) => {
        if (processors[index].type === 'classifier') {
          return;
        }
      
        const updatedProcessors = processors.filter((_, i) => i !== index);
        setProcessors(updatedProcessors);
    };

    const handleProcessorChange = (index, field, value) => {
        const updatedProcessors = processors.map((processor, i) => 
            i === index ? { ...processor, [field]: value } : processor
        );
        setProcessors(updatedProcessors);
    };

    return (
        <form className={styles.form}>
            {processors.map((processor, index) => {
                // Skip rendering classifier fields if project_type is 'unstructured'
                if (project_type === 'unstructured' && processor.type === 'classifier') {
                    return null;
                }
    
                // For 'structured' project type, include logic to ensure only one classifier is rendered
                // This logic could be more complex depending on how you decide which classifier to render
    
                return (
                    <div key={index} className={styles.processor}>
                        <label htmlFor='processor'>{processor.type}</label>
                        {index !== 1 && (
                            <button className={styles.deletebutton} type="button" onClick={() => removeProcessor(index)}>X</button>
                        )}
                        <input
                            className={styles.input_field}
                            value={processor.name}
                            onChange={(e) => handleProcessorChange(index, 'name', e.target.value)}
                            placeholder="Processor Name"
                        />
                        <input
                            className={styles.input_field}
                            value={processor.url}
                            onChange={(e) => handleProcessorChange(index, 'url', e.target.value)}
                            placeholder="Processor URL"
                        />
                    </div>
                );
            })}
            {/* Button to add new processor */}
            <div className={styles.buttonWrapper}>
                <button className={styles.button} type="button" onClick={addProcessor}>Add extractor</button>
            </div>
        </form>
    )
}

export default KalDocsForm;