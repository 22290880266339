import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useFileMng = () => {
  const gateway = useSelector(state => state.data.gateway)

  const create = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/file_mng/create`, 'POST', body);
      return response.Message
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create new file"
      }
    }
  };

  const update = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/file_mng/update`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to update file record"
      }
    }
  };

  const deleteFile = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/file_mng/delete`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to delete file"
      }
    }
  };

  const get = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/file_mng/get`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get file"
      }
    }
  };


  const getAll = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/file_mng/get_all`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get all files"
      }
    }
  };

  const updateUrl = async (body) => {
    try{
      const response = await makeRequest(`${gateway}/api/file_mng/update_file_url`, 'POST', body)
      return response
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get all files"
      }
    }
  }

  return { 
    create,
    update, 
    deleteFile,
    get, 
    getAll,  
    updateUrl
  };
};

export default useFileMng;
