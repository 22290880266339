import React, { useState } from 'react';
import styles from "../components/Forms/css/PopupForm.module.css"; 
import { useDispatch } from 'react-redux';
import { setToken, setRefreshToken, setRole, setOrgId, setOrgName, setOrgLogo,setUserName} from '../../store/user-slice';
import { setShowToast, setToastMessage } from "../../store/data-slice"
import { login } from '../api/firebase'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import useOrg from '../api/organization';
import useUser from '../api/users';
import { useNavigate } from 'react-router-dom';

function LoginForm({setLoading}) {
    const navigate = useNavigate();
    const {get_by_fi} = useUser();
    const {get} = useOrg();
    const [loginData, setLoginData] = useState({ email: '', password: '' ,returnSecureToken: true});
    const dispatch = useDispatch();

    const handleLoginNavigation = async (localId) => {
        let body = {
            id: localId
        }
        try {
            const response = await get_by_fi(body);
            console.log(response)
            if(response.organization_id){
                dispatch(setOrgId(response.organization_id))
            }
            dispatch(setRole(response.role))
            dispatch(setUserName(response.user_name))
            body = {
                org_id: response.organization_id
            }
            if(response.role === 'super_admin'){
                dispatch(setToastMessage('Login successful'));
                dispatch(setShowToast(true));
                navigate('/super-admin'); 
            }
            else {
                if(response.role === 'admin' || response.role === 'user'){
                    try{
                        const org_response = await get(body)
                        dispatch(setOrgName(org_response.organization_name))
                        if (org_response.logo){
                            dispatch(setOrgLogo(org_response.logo))
                        }
                        dispatch(setToastMessage('Login successful'));
                        dispatch(setShowToast(true));
                        navigate('/projects')
                    }
                    catch(err){
                        dispatch(setToastMessage('login failed'))
                        dispatch(setShowToast(true))
                        setLoading(false);
                    }
                }
                else{
                    dispatch(setToastMessage('login failed'))
                    dispatch(setShowToast(true))
                }
            }
        }
        catch(err){
            setLoading(false);
        }
        setLoading(false);
    }

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth(); // Make sure auth is initialized with getAuth(app) if not globally available
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, provider);
            // Proceed with your login logic after successful Google sign-in
            try {
                console.log(result)
                dispatch(setToken(result.user.stsTokenManager.accessToken));
                dispatch(setRefreshToken(result.user.stsTokenManager.refreshToken));
                handleLoginNavigation(result.user.reloadUserInfo.localId)
            }
            catch(error) {
                dispatch(setToastMessage('some error occurred'));
                dispatch(setShowToast(true));
                setLoading(false);
            }
        } catch (error) {
            dispatch(setToastMessage(error.message || 'Google sign-in failed.'));
            dispatch(setShowToast(true));
            setLoading(false);
        }
    };
    

    const handleChange = (event) => {
        setLoginData({ ...loginData, [event.target.id]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        if (!loginData.email || !loginData.password) {
            dispatch(setToastMessage('Please enter both email and password.'));
            dispatch(setShowToast(true));
        } else {
            try {
                setLoading(true);
                const response = await login(loginData.email, loginData.password);
                console.log(response)
                dispatch(setToken(response.idToken));
                dispatch(setRefreshToken(response.refreshToken));
                let localId = response.localId
                try {
                    handleLoginNavigation(localId)
                }
                catch(error) {
                    dispatch(setToastMessage('some error occurred'));
                    dispatch(setShowToast(true));
                    setLoading(false);
                }
            } catch (error) {
                dispatch(setToastMessage(error.message || 'Login failed.'));
                dispatch(setShowToast(true));
                setLoading(false);
            }
        }
        setLoading(false);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.frame} ${styles.login}`}>
                <div className={styles.form}>
                    <div className={styles.label}>
                        <div className={styles.text_wrapper}>Login</div>
                        {/* <img className={styles.google} src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Sign in with Google" onClick={handleGoogleSignIn}/> */}
                        <button id="googleSignInButton" className={styles.google} onClick={handleGoogleSignIn}>
                            <img className={styles.google_img} src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google sign-in" />
                            <span>Sign in with Google</span>
                        </button>
                    </div>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <label htmlFor='email'>Email</label>
                        <input id='email' type='text' className={styles.input_field} value={loginData.email} onChange={handleChange} />

                        <label htmlFor='password'>Password</label>
                        <input id='password' type='password' className={styles.input_field} value={loginData.password} onChange={handleChange} />

                        <button type="submit" className={styles.button}>Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;