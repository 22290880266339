import React from 'react';
import styles from "../Forms/css/PopupForm.module.css"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

function Support(){
    return (
        <div className={styles.container}>
          <div className={`${styles.frame} ${styles.login}`}>
            <div className={styles.form}>
              <div className={styles.label}>
                <h2>Contact Us</h2>
              </div>
              <div className={styles.processor}>
                <LocationOnIcon />
                <span>North High Tech Park, Bar Lev</span>
              </div>
              <div className={styles.processor}>
                <PhoneIcon />
                <span>(+972) 77-589-6534</span>
              </div>
              <div className={styles.processor}>
                <EmailIcon />
                <span>info@kaleidoo.ai</span>
              </div>
            </div>
          </div>
        </div>
      );
      
}

export default Support;