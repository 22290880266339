import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useSourceMng = () => {
  const gateway = useSelector(state => state.data.gateway)

  const create = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source_mng/create`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create source connection"
      }
    }
  };

  const deleteSourceConn = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source_mng/delete`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to delete source connection"
      }
    }
  };

  const get = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source_mng/get`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get source connection"
      }
    }
  };


  const update = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source_mng/update`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to update source connection"
      }
    }
  };

  const getAll = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/source_mng/get_all`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get all source connections"
      }
    }
  };

  return { 
    create,
    deleteSourceConn,
    get,
    update, 
    getAll,  
  };
};

export default useSourceMng;
