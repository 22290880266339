import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gateway: process.env.REACT_APP_API_GATEWAY_URL, 
  sourceTypes: null,
  destTypes: null,
  sideBarNavState: 'home',
  filteredSuggestionEmpty: false,
  docsProjects: [],
  mediaProjects: [],
  voiceProjects: [],
  docsProjectsToShow: [],
  inboxData: [],
  approvedData: [],
  notApprovedData: [],
  needApprovedData: [],
  toastMessage: '',
  showToast: false,
  avaliableProducts: []
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSourceTypes:(state, action) => {
      state.sourceTypes = action.payload
    },
    setDestTypes:(state, action) => {
      state.destTypes = action.payload
    },
    setDocsProjects: (state, action) => {
      state.docsProjects = action.payload
    },
    setMediaProjects: (state, action) => {
      state.mediaProjects = action.payload
    },
    setVoiceProjects: (state, action) => {
      state.voiceProjects = action.payload
    },
    setdocsProjectsToShow: (state, action) => {
      state.docsProjectsToShow = action.payload
    },
    setFilteredSuggestionEmpty: (state, action) => {
      state.filteredSuggestionEmpty = action.payload
    },
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload
    },
    setShowToast: (state, action) => {
      state.showToast = action.payload
    },
    setSideBarNavState: (state, action) => {
      state.sideBarNavState = action.payload
    },
    setInboxData: (state, action) => {
      state.inboxData = action.payload
    },
    setApprovedData: (state, action) => {
      state.approvedData = action.payload
    },
    setNotApprovedData: (state, action) => {
      state.notApprovedData = action.payload
    },
    setNeedApprovedData: (state, action) => {
      state.needApprovedData = action.payload
    },
    setAvaliableProducts: (state, action) => {
      state.avaliableProducts = action.payload
    },
    resetData: () => initialState
  },
});

export const { 
  setDestTypes, 
  setSourceTypes, 
  setDocsProjects, 
  setMediaProjects, 
  setVoiceProjects,
  setdocsProjectsToShow,
  setFilteredSuggestionEmpty,
  setShowToast,
  setToastMessage,
  setSideBarNavState,
  setInboxData,
  setApprovedData,
  setNotApprovedData,
  setNeedApprovedData,
  resetData,
} = dataSlice.actions;
export default dataSlice;
