import axios from 'axios';

export const login = async (email, password) => {
  try {
    const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
    const response = await axios.post(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${apiKey}`, {
      email,
      password,
      returnSecureToken: true
    });
    return response.data;
  } catch (error) {
    throw error.response.data; 
  }
};

