import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useProductMng = () => {
  const gateway = useSelector(state => state.data.gateway)

const create = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/product/kaldocs/create`, 'POST', body);
    return response.Message;
  } catch (err) {
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create new product record"
      }
  }
};

const getWorkspaceProduct = async (body) => {
  try {
    const response = await makeRequest(`${gateway}/api/product/kaldocs/get`, 'POST', body);
    return response
  }
  catch(err){
    let error = err.response.data
    if(error["Message"]){
      throw error["Message"]
    }
    else{
      // eslint-disable-next-line no-throw-literal
      throw "Failed to get project's product record"
    }
  }
};

const updateProjectProduct = async (body) => {
  try{
    const response = await makeRequest(`${gateway}/api/product/kaldocs/ui_update`, 'POST', body)
    return response.Message
  }
  catch(err){
    let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to update product record"
      }
  }
}

return { 
  create,
  getWorkspaceProduct,
  updateProjectProduct
};
};

export default useProductMng;