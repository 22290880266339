import React , { useState} from 'react';
import styles from "./css/PopupForm.module.css"
import Toast from "../../../tools/Toast"
import useUser from '../../api/users';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage, setShowToast } from '../../../store/data-slice';

function CreateUserForm({handleShow, superAdmin}){
    const { create } = useUser();
    const orgIdFromStore = useSelector((state) => state.user.org_id);
    const orgId = superAdmin ? '' : orgIdFromStore;
    const [formData, setFormData] = useState({org_id: orgId, user_name: '', first_name: '', last_name: '', email: '', role: ''})
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.id]: event.target.value });
    };

    const handleClose = () => {
        handleShow(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        // Custom validation logic
        if (formData.user_name === '' || formData.email === '' || formData.first_name === '' || formData.last_name === '' || formData.role === '' ) {
            dispatch(setToastMessage('Please fill all the data.'))
            dispatch(setShowToast(true))
        } 
        else {
            try {
                await create(formData);
                dispatch(setToastMessage('created successful!'))
                dispatch(setShowToast(true))
                handleClose()
            } catch (error) {
                dispatch(setToastMessage(error || 'creation failed.'))
                dispatch(setShowToast(true))
            }
        }
    };

    return (
        <div className={`${styles.container} ${styles.modal}`}>
            <div className={`${styles.frame} ${styles.modalFrame}`}>
                <button className={styles.closeButton} onClick={handleClose} >&times;</button>
                <div className={styles.form}>
                    <div className={styles.label}>
                    <div className={styles.text_wrapper}>Create New User</div>
                    </div>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {superAdmin && <>
                        <label htmlFor='org_id'>Organization Id</label>
                        <input id='org_id' className={styles.input_field} value={formData.org_id} onChange={handleChange} ></input>
                        </>}
                        <label htmlFor='user_name'>User name</label>
                        <input id='user_name' className={styles.input_field} value={formData.user_name} onChange={handleChange}></input>

                        <label htmlFor='first_name'>First name</label>
                        <input id='first_name' className={styles.input_field} value={formData.first_name} onChange={handleChange}></input>

                        <label htmlFor='last_name'>Last Name</label>
                        <input id='last_name' className={styles.input_field} value={formData.last_name} onChange={handleChange}></input>

                        <label htmlFor='email'>Email</label>
                        <input id='email' className={styles.input_field} value={formData.email} onChange={handleChange}></input>

                        <label htmlFor='role'>Role</label>
                        <select id='role' className={styles.select_field} value={formData.role} onChange={handleChange}>
                            <option value=''></option>
                            <option value='admin'>Admin</option>
                            <option value='user'>User</option>
                        </select>

                        <button type="submit" className={styles.button}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateUserForm;