import makeRequest from './makeRequest'; 
import {useSelector} from 'react-redux'

const useOrg = () => {
  const gateway = useSelector(state => state.data.gateway)
  const create = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/org_mng/create`, 'POST', body);
      return response
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create new organization"
      }
    }
  };

  const update = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/org_mng/update`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to update organization"
      }
    }
  };

  const deleteOrg = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/org_mng/delete`, 'POST', body);
      return response.Message;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to create delete organization"
      }
    }
  };

  const get = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/org_mng/get`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get organization"
      }
    }
  };


  const getProducts = async (body) => {
    try {
      const response = await makeRequest(`${gateway}/api/org_mng/get_products`, 'POST', body);
      return response;
    } catch (err) {
      let error = err.response.data
      if(error["Message"]){
        throw error["Message"]
      }
      else{
        // eslint-disable-next-line no-throw-literal
        throw "Failed to get organization's products"
      }
    }
  };

  return { 
    create,
    update, 
    deleteOrg,
    get, 
    getProducts,  
  };
};

export default useOrg;
