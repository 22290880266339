import { useState } from 'react';
import CreateOrgForm from '../../components/Forms/CreateOrgForm';
import CreateUserForm from '../../components/Forms/CreateUserForm';
import NavBar from '../../../tools/Navbar';

function SuperAdminPage(){
    const [showPopup, setShowPopup] = useState(false);

    const handleShow = (show) => {
        setShowPopup(show);
    }
    return (
        <>
        <NavBar showPopup={setShowPopup} title={'KalSense'} />
        <CreateOrgForm/>
        {showPopup && <CreateUserForm handleShow={handleShow} superAdmin={true}/>}
        </>
    )
}

export default SuperAdminPage;