import React, { useState } from 'react';
import NavBar from '../../../tools/Navbar';
import LoginForm from '../Login';
import LoadingCircle from '../../../tools/LoadingCircle';

function LoginPage(){
    const [loading, setLoading] = useState(false);
    return (
        <>
        {loading && <LoadingCircle/>}
        <NavBar title={'KalSense'}/>
        <LoginForm setLoading={setLoading}/>
        </>
    )
}

export default LoginPage;