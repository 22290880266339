import React, { useEffect, useState } from 'react';
import NavBar from '../../../tools/Navbar';
import Sidebar from '../../../kaldocs/components/Sidebar';
import styles from "./css/ProjectPage.module.css";
import { useSelector, useDispatch } from 'react-redux';
import DataTable from '../../../kaldocs/components/Table';
import LoadingCircle from '../../../tools/LoadingCircle';
import DocumentView from '../documentView/DocumentView';
import {
    setInboxData,
    setApprovedData,
    setNotApprovedData,
    setNeedApprovedData,
} from '../../../store/data-slice'
import useFileMng from '../../../kalstudio/api/fileManager';
import Dashboard from '../Dashboard/Dashboard';

function KalvoiceProjectPage({projectParams}) {
    const [showPopup, setShowPopup] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [currentTab, setCurrentTab] = useState('Inbox');
    const [showDetails, setShowDetails] = useState(false);
    const [rowData, setRowData] = useState(null);
    const {getAll} = useFileMng();
    const orgId = useSelector((state) => state.user.org_id);
    const inboxData = useSelector((state) => state.data.inboxData);
    const approvedData = useSelector((state) => state.data.approvedData);
    const notApprovedData = useSelector((state) => state.data.notApprovedData);
    const needApprovedData = useSelector((state) => state.data.needApprovedData);
    const dispatch = useDispatch();

    const handleSetCurrentTab = (name) => {
        setShowDetails(false);
        setRowData(null);
        setCurrentTab(name);
    }

    useEffect(()=>{
        if(rowData){
            setShowDetails(true);
        }
    },[rowData])

    const processFileNames = (filesData) => {
        return filesData.map((file, index) => {
            return { 
                key: file.key,
                date: file.date, 
                name: file.name, 
                size: file.size,
                url: file.url,
                tags: [file.file_type],
                result: file.result
            };
        });
    };

    const getFoldersData = async (status) => {
        try{
            let body = {
                org_id: orgId,
                id: projectParams.project_id,
                status: status
            };
            const response = await getAll(body);
            return processFileNames(response);
        }
        catch(err){

        }
    };

    const fetchAndSetData = async () => {
        const inboxData = await getFoldersData('inbox');
        const needApprovedData = await getFoldersData('need_approved');
        const approvedData = await getFoldersData('approved');
        const notApprovedData = await getFoldersData('not_approved');

        dispatch(setInboxData(inboxData));
        dispatch(setNeedApprovedData(needApprovedData));
        dispatch(setApprovedData(approvedData));
        dispatch(setNotApprovedData(notApprovedData));
        setDataFetched(true);
    };

    const refresh = () => {
        setDataFetched(false);
        setShowDetails(false);
        fetchAndSetData();
    }

    useEffect(() => {    
        fetchAndSetData();
    }, [orgId, projectParams.project_id]);
    

    return (
      <>
        <NavBar showSearch={true} setRowData={setRowData} showPopup={setShowPopup} text="Search for a specific document" title={'KalAudio'}/>
        <Sidebar currentTab={currentTab} setCurrentTab={handleSetCurrentTab} inboxLength={inboxData.length} needApprovedLength={needApprovedData.length} approvedLength={approvedData.length} notApprovedLength={notApprovedData.length}/>
        {dataFetched && <div className={styles.container}>
            {!showDetails && <>
                {currentTab === 'Inbox' && <DataTable org_id={orgId} project_id={projectParams.project_id} setShowView={setShowDetails} setRowData={setRowData} data={inboxData} />}
                {currentTab === 'Need Approved' && <DataTable org_id={orgId} project_id={projectParams.project_id} setShowView={setShowDetails} setRowData={setRowData} data={needApprovedData}/>}
                {currentTab === 'Approved' && <DataTable org_id={orgId} project_id={projectParams.project_id} setShowView={setShowDetails} setRowData={setRowData} data={approvedData} />}
                {currentTab === 'Not Approved' && <DataTable org_id={orgId} project_id={projectParams.project_id} setShowView={setShowDetails} setRowData={setRowData} data={notApprovedData}/>}
                {currentTab === 'Home' && <Dashboard project_id={projectParams.project_id}/>}
            </>
            }
            {showDetails && <DocumentView refresh={refresh} project_id={projectParams.project_id} onBack={(e)=> setShowDetails(false)} activeTab={currentTab} rowData={rowData}/>}
        </div>}
        {!dataFetched && <LoadingCircle/>}
      </>
    )
  }  

export default KalvoiceProjectPage;


