// MetricCard.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { ReactComponent as YourIcon } from './path-to-your-icon.svg'; 

const IconWrapper = styled(Box)(({ theme }) => ({
  // Add your styles here
}));

const MetricCard = ({ icon: IconComponent, metric, title, change }) => (
  <Card sx={{ height: 150,width: 275, boxShadow: 3 }}>
    <CardContent>
      <IconWrapper>
        <IconComponent /> {/* Use your icon component */}
      </IconWrapper>
      <Typography variant="h6" component="div">
        {metric}
      </Typography>
      <Typography color="text.secondary">
        {title}
      </Typography>
      <Typography color="text.secondary" sx={{ fontSize: 14 }}>
        {change}
      </Typography>
    </CardContent>
  </Card>
);

export default MetricCard