import React, { useState, useEffect } from 'react';
import Workspace from './WorkSpace';
import styles from './css/WorkspacesContainer.module.css';
import DocsIcon from "../../tools/icons/docs.svg"
import { useSelector } from 'react-redux';
import { setDocsProjects, setShowToast, setdocsProjectsToShow, setToastMessage } from '../../store/data-slice';
import useWorkspace from '../api/workspace';
import useProcessProjects from '../api/processProjects';
import { useDispatch } from 'react-redux';

const WorkspacesContainer = () => {
  const docsProjects = useSelector(state => state.data.docsProjects);
  const mediaProjects = useSelector(state => state.data.mediaProjects);
  const voiceProjects = useSelector(state => state.data.voiceProjects);
  const docsProjectsToShow = useSelector(state => state.data.docsProjectsToShow);
  const isFilterEmpty = useSelector(state => state.data.filteredSuggestionEmpty);
  const {deleteWorkspace} = useWorkspace();
  const processProjectsData = useProcessProjects();
  const dispatch = useDispatch();

  const [allProjects, setAllProjects] = useState([]);
  const [displayProjects, setDisplayProjects] = useState([]);


  useEffect(() => {
    setAllProjects([...docsProjects, ...mediaProjects, ...voiceProjects]);
  }, [docsProjects, mediaProjects, voiceProjects]);


  const getIconForProduct = (productType) => {
    const icons = {
      'KalDocs': 'docs',
      'KalVoice': 'voice',
      'KalChat': 'chat'
    };
    return icons[productType];
  };

  useEffect(() => {
    const newDisplayProjects = docsProjectsToShow.length > 0
      ? allProjects.filter(project => {
          const namesToShow = docsProjectsToShow.map(item => item.name);
          return namesToShow.includes(project.name);
        })
      : (isFilterEmpty ? [] : []);

    setDisplayProjects(newDisplayProjects);
  }, [docsProjectsToShow, allProjects, isFilterEmpty]);

  // Map the projects to the workspace format
  const workspaces = displayProjects.map(project => ({
    id: project._id,
    icon: getIconForProduct(project.product),
    label: project.name,
    type: project.type,
    product: project.product,
    isReady: project.product_defined && project.dest_defined && project.source_defined
  }));

  const handleDelete = async (id, org_id) =>{
    try {
      const body = {org_id: org_id, id: id}
      deleteWorkspace(body).then(()=>{
        processProjectsData(org_id);
        dispatch(setToastMessage('project deleted'))
        dispatch(setShowToast(true));
      })
    }
    catch(err){
      console.error(err);
    }
  }
  

  return (
    <div className={styles.container}>
      {workspaces.map((workspace, index) => (
        <Workspace type={workspace.type} initIsReady={workspace.isReady} id={workspace.id} key={index} icon={workspace.icon} label={workspace.label} product={workspace.product} handleDelete={handleDelete}/>
      ))}
    </div>
  );
};

export default WorkspacesContainer;
