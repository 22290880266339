import React , { useEffect, useState ,useImperativeHandle, forwardRef} from 'react';
import styles from "./css/Form.module.css"
// import { useSelector } from 'react-redux';
import useDestMng from '../../api/DestinationsManager';

function CreateDestForm({prevData,formData, setFormData, onSubmit, id}, ref){
    const [isInitialized, setIsInitialized] = useState(false);
    const {create, update} = useDestMng();

    let typeOptions = {
        "types": ["email"]
    }

    useImperativeHandle(ref, () => ({
        submitForm: (type) => {
            if (type === 'Edit'){
                handleEdit({ preventDefault: () => {} }); 
            }
            else{
                handleSubmit({ preventDefault: () => {} }); 
            }
        }
    }));

    useEffect(()=> {
        //get type options
    }, [])


    useEffect(()=>{
        if(isInitialized || !prevData){
            loadData(formData.type);
        }
        else{
            setIsInitialized(true);
        }
    }, [formData.type])

    const loadData = (type) => {
        let data = {}
        if (type !== ''){
            data = {
                address: ''
            };
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            attributes: {...data}  
        }));
    };

    const handleAttributesChange = (key, newValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            attributes: {
                ...prevFormData.attributes,
                [key]: newValue
            }
        }));
    };  

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevFormData => {
            // Log to check the new state
            const updatedFormData = { ...prevFormData, [id]: value };
            return updatedFormData;
        });
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        // Custom validation logic
        if (formData.name === '' || formData.type === '' || formData.attributes.address === '') {
            onSubmit({isValid: false, error: 'please fill all the data'})
        } 
        else {
            try{
                await create(formData)
                onSubmit({isValid: true})
            }catch (err){
                onSubmit({isValid: false, error: err})
            }
        }
    };

    const handleEdit = async (event) => {
        event.preventDefault();
        if (formData.name === '' || formData.type === '' || formData.attributes.address === '') {
            onSubmit({isValid: false, error: 'please fill all the data'})
        } 
        else{
            try{
                const body = {...formData, id: id, workspace_id: formData.id}
                await update(body)
                onSubmit({isValid: true})
            }
            catch(error){
                onSubmit({isValid: false, error: error})
            }
        }

    }

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.frame}`}>
                <div className={styles.form}>
                    <div className={styles.label}>
                    <div className={styles.text_wrapper}><span className={styles.optionalIndicator}> {true ? '*' : ''} </span>Create Dest Connection</div>
                    </div>
                    <form className={styles.form}>
                    <div className={styles.processor}>
                    <label htmlFor='name'>name</label>
                    <input id='name' className={styles.input_field} value={formData.name} onChange={handleChange}></input>

                    <label htmlFor='type'>connection type</label>
                    <select id='type' className={styles.select_field} value={formData.type} onChange={handleChange}>
                        <option value=""></option>
                        {typeOptions.types.map((type)=>(
                            <option value={type}>{type}</option>
                        ))}
                    </select>
                    
                    {Object.entries(formData.attributes).map(([key, value], index) => (
                        <>
                            <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                            <input
                                className={styles.input_field}
                                id={key}
                                type={key === 'password' ? 'password' : 'text'}
                                value={value}
                                onChange={(e) => handleAttributesChange(key, e.target.value)}
                            />
                        </>
                    ))}
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateDestForm);