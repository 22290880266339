import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedSuperAdminRoute = () => {
  const isSuperAdmin = useSelector((state) => state.user.role) === 'super_admin'? true: false;

  return isSuperAdmin ? <Outlet /> : <Navigate to="/" />;
};

export const ProtectedUsersRoute = () => {
    const isNotSuperAdmin = useSelector((state) => state.user.role) === 'super_admin'? false: true;
    const role = useSelector((state)=> state.user.role)
    const roleExists = role !== null? (role === "admin" ? true : (role === "user" ? true : false)) : false;
    console.log("role is: ", useSelector((state)=> state.user.role))
    return (roleExists && isNotSuperAdmin) ? <Outlet /> : <Navigate to="/" />;
};