import React from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import styles from './css/DocumentView.module.css';
import resultStyles from "./css/ResultView.module.css"

const { TabPane } = Tabs;

const ResultView = ({ jsonData }) => {
  const { type } = useParams();

  // Function to render each key-value pair
  // Function to render a field (either top-level or nested)
  const renderField = (key, value, isNested = false) => (
    <div key={key} className={isNested ? resultStyles.nestedFormGroup : resultStyles.formGroup}>
      <label htmlFor={key} className={resultStyles.formLabel}>{key}</label>
      <input type="text" id={key} value={value} readOnly className={resultStyles.formInput} />
    </div>
  );

  // Function to render each entry in the JSON data
  const renderEntry = ([key, value]) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Handle nested objects
      return (
        <div key={key}>
          <h4 className={resultStyles.objectTitle}>{key}</h4>
          {Object.entries(value).map(entry => renderField(entry[0], entry[1], true))}
        </div>
      );
    } else if (Array.isArray(value)) {
      // Handle arrays of objects (like 'line_item')
      return (
        <div key={key}>
          <h4 className={resultStyles.objectTitle}>{key}</h4>
          {value.map((item, index) => (
            <div key={index}>
                <h4 className={resultStyles.objectTitle} >{index}:</h4>
              {Object.entries(item).map(entry => renderField(entry[0], entry[1], true))}
            </div>
          ))}
        </div>
      );
    } else {
      // Handle regular fields
      return renderField(key, value);
    }
  };
  const unstructuredText = type === 'unstructured' && jsonData.transcript ? jsonData.transcript.text: '';
  console.log(unstructuredText)
  const textContentClassName = type === 'unstructured'
    ? `${resultStyles.textContentView} ${resultStyles.rightToLeftText}` // Apply RTL and pre-wrap styles for unstructured project type
    : resultStyles.textContentView; // Default styling for structured project type or others
    return (
      <div className={styles.resultView}>
        <Tabs defaultActiveKey="1" animated>
          <TabPane tab="Details" key="1">
            <div className={resultStyles.tabContent}>
              <h3 className={resultStyles.sectionTitle}>Result</h3>
              {
                type === 'unstructured'
                ? <div className={textContentClassName}>
                    {/* Display the unstructured text content here */}
                    <pre>{unstructuredText}</pre>
                  </div>
                : <form>
                    {/* Render structured JSON data as form inputs */}
                    {Object.entries(jsonData).map(entry => renderEntry(entry))}
                  </form>
              }
            </div>
          </TabPane>
          <TabPane tab="Note" key="2">
            <div className={resultStyles.tabContent}>Notes content goes here...</div>
          </TabPane>
          <TabPane tab="History" key="3">
            <div className={resultStyles.tabContent}>History content goes here...</div>
          </TabPane>
        </Tabs>
      </div>
    );
  };

export default ResultView;
