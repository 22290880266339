// useProcessProjects.js
import { useDispatch } from 'react-redux';
import { setDocsProjects, setVoiceProjects, setMediaProjects } from '../../store/data-slice';
import  useWorkspace  from './workspace';

const useProcessProjects = () => {
    const dispatch = useDispatch();
    const {getAll} = useWorkspace();

    const processProjectsData = async (orgId) => {
        try {
            const body = { org_id: orgId };
            const projects = await getAll(body);
            const docs = projects.filter(item => item.product.toLowerCase() === 'kaldocs');
            const voice = projects.filter(item => item.product.toLowerCase() === 'kalvoice');
            const media = projects.filter(item => item.product.toLowerCase() === 'kalchat');
            dispatch(setDocsProjects(docs));
            dispatch(setVoiceProjects(voice));
            dispatch(setMediaProjects(media));
        } catch (err) {
            console.error(err);
        }
    };

    return processProjectsData;

};

export default useProcessProjects;
