import { configureStore } from '@reduxjs/toolkit';
import dataSlice from './data-slice.js'; 
import userSlice from './user-slice.js';

const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
    user: userSlice.reducer,
  },
});

export default store;