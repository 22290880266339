// Dashboard.js
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import MetricCard from './MetricCard';
import RevenueAreaChart from './RevenueAreaChart';
import RevenueSnapshot from './RevenueSnapshot';

// Icons can be imported from @mui/icons-material or use custom SVGs
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from "./css/Dashboard.module.css"

import useSourceService from '../../../kalstudio/api/sourceService';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage, setShowToast } from '../../../store/data-slice';
import CircularProgress from '@mui/material/CircularProgress'; 


const Dashboard = ({project_id}) => {
  
  const {scan} = useSourceService();
    const orgId = useSelector((state) => state.user.org_id);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    // Mock data for the Area Chart
    const areaChartData = [
        { year: 2020, revenue: 24000 },
        { year: 2021, revenue: 32100 },
        { year: 2022, revenue: 22900 },
        { year: 2023, revenue: 35000 },
    ];
  
    // Mock data for the Pie Chart
    const pieChartData = [
        { name: 'Secretariat', value: 162 },
        { name: 'Board meeting', value: 200 },
        { name: 'Legal advice', value: 100 }
    ];

    const handleScan = async () => {
      try {
        setLoading(true)
          const body = {
              id: project_id,
              org_id: orgId
          }
          const response = await scan(body)
          dispatch(setToastMessage(response))

      } catch (err) {
          dispatch(setToastMessage(err))
      } finally {
        setLoading(false);
        dispatch(setShowToast(true))
      }
  }
  

  return (
    <div style={{ margin: 'auto', maxWidth: '1200px', padding: '20px' }}>
        <div className={styles.miniNav} style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={handleScan}
            disabled={loading}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'center',
              opacity: loading ? 0.5 : 1,
            }}
          >
              Scan documents
            <div style={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? (
            <CircularProgress size={24} style={{ color: 'black' }} />
              ) : (
                <span style={{ display: 'inline-block', width: 24, height: 24 }}></span>
            )}
        </div>
        </button>
      </div>
      <Grid container spacing={4}>
        {/* Metric Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={AttachMoneyIcon}
            metric="$8,048,714"
            title="Incom"
            change="+2.8%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={TrendingUpIcon}
            metric="$896,750"
            title="Revenue Total"
            change="+1.4%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={AccessTimeIcon}
            metric="30-01-2024 20:25"
            title="Last Update"
            change=""
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={InsertDriveFileIcon}
            metric="562"
            title="Processed files"
            change="+1.9%"
          />
        </Grid>
        
        {/* Charts */}
        <Grid item xs={12} md={6}>
          <RevenueSnapshot data={pieChartData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueAreaChart data={areaChartData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
