import axios from 'axios';
import store from '../../store'; 
import { setToken, setRefreshToken } from '../../store/user-slice';

const isTokenExpired = (token) => {
  const expirationTimeInSecondsSinceEpoch = JSON.parse(atob(token.split('.')[1])).exp;
  const currentTimeInSecondsSinceEpoch = Math.floor(Date.now() / 1000);
  return (currentTimeInSecondsSinceEpoch >= expirationTimeInSecondsSinceEpoch);
};

const refreshAccessToken = async (refreshToken) => {
  const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const refreshTokenUrl = `https://securetoken.googleapis.com/v1/token?key=${apiKey}`;
  const response = await axios.post(refreshTokenUrl, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
  return response.data;
};

const makeRequest = async (url, method, body) => {
  let token = store.getState().user.token;
  if (isTokenExpired(token)) {
    const refreshToken = store.getState().user.refreshToken;
    const refreshResponse = await refreshAccessToken(refreshToken);
    token = refreshResponse.id_token; // The new ID token
    store.dispatch(setToken(token));
    store.dispatch(setRefreshToken(refreshResponse.refresh_token)); 
  }

  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  try {
    let response;
    if (method === 'POST') {
      response = await axios.post(url, body, { headers });
    } else {
      response = await axios.get(url, { headers });
    }
    return response.data;
  } catch (err) {
    throw err; 
  }
};

export default makeRequest;
