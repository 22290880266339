import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../../tools/Navbar';
import Sidebar from '../../../tools/Sidebar';
import WorkspacesContainer from "../WorkspacesContainer"
import LoadingCircle from '../../../tools/LoadingCircle';
import CreateProjectForm from '../Forms/CreateProjectForm';
import useProcessProjects from '../../api/processProjects';
import CreateUserForm from '../Forms/CreateUserForm';


function ProjectsPage(){
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const docsProjects = useSelector(state => state.data.docsProjects)
    const mediaProjects = useSelector(state => state.data.mediaProjects)
    const voiceProjects = useSelector(state => state.data.voiceProjects)
    const orgId = useSelector((state) => state.user.org_id);
    const processProjectsData = useProcessProjects();


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await processProjectsData(orgId);
            setLoading(false);
        };
    
        fetchData();
    }, [orgId]); 

    return(
        <>
        {loading && <LoadingCircle/>}
        <NavBar showSearch={true} plus={true} showPopup={setShowPopup} text="Search for a specific project" title={'KalSense'}/>
        <Sidebar 
        docsProjectsLen={docsProjects.length}
        mediaProjectsLen={mediaProjects.length}
        voiceProjectsLen={voiceProjects.length}
        />
        <WorkspacesContainer/>
        {showPopup === 'project' && <CreateProjectForm setLoading={setLoading} handleClose={(e)=> setShowPopup(false)}/>}
        {showPopup === 'user' && <CreateUserForm handleShow={setShowPopup}/>}
        </>
    )
}

export default ProjectsPage;