import React , { useState, useImperativeHandle, forwardRef, useEffect} from 'react';
import styles from "./css/Form.module.css"
import KalDocsForm from './KalDocsForm';
import useProductMng from '../../api/productManager';

function CreateProductDataForm({project_type, product, onSubmit, formData, setFormData}, ref){
    const {create, updateProjectProduct} = useProductMng();
    const [processors, setProcessors] = useState([
        {name: '', type: 'classifier', url: '' },
        {name: '', type: 'extractor', url: ''}
    ]);

    useEffect(()=>{
        console.log(formData)
        if(formData.processors){
            setProcessors(formData.processors)
        }
    },[formData.processors])

    useImperativeHandle(ref, () => ({
        submitForm: (type) => {
            if(type === 'Edit'){
                handleEdit({preventDefault: () => {}})
            }else{
                handleSubmit({ preventDefault: () => {} }); 
            }
        }
    }));

    const handleSubmit = async (event) => {
        event.preventDefault();
        let newFormData = {};
        let valid = true;
    
        if (product === 'KalDocs') {
            const hasExtractor = processors.some(proc => proc.type === 'extractor');
            const hasClassifier = processors.some(proc => proc.type === 'classifier');
            
            // If 'structured', require both an extractor and a classifier
            if (project_type === 'structured' && (!hasExtractor || !hasClassifier)) {
                onSubmit({isValid: false, error: 'Please fill at least one extractor and one classifier for structured projects'});
                valid = false;
            }
    
            // Only check for missing names and URLs if the project is structured or if it's a strict requirement for all project types
            if (valid && (project_type === 'structured' || /* Condition for other projects requiring full data */ false)) {
                const missingName = processors.some(proc => proc.name === '');
                const missingUrl = processors.some(proc => proc.url === '');
                if (missingName || missingUrl) {
                    onSubmit({isValid: false, error: 'Please fill all the data for each processor'});
                    valid = false;
                }
            }
    
            // Prepare the form data if the validation passed so far
            if (valid) {
                newFormData = {...formData, processors};
                setFormData(newFormData);
            }
        }
    
        try {
            if (valid) {
                console.log(newFormData); // Debugging purposes
                await create(newFormData);
                onSubmit({isValid: true});
            }
        } catch (error) {
            onSubmit({isValid: false, error: String(error)});
        }
    };
    

    const handleEdit = async (event) => {
        event.preventDefault();
        let newFormData = {}
        if (product === 'KalDocs') {
            newFormData = {...formData, processors}
            setFormData({ ...formData, processors})
        }
        try{
            await updateProjectProduct(newFormData)
            onSubmit({isValid: true})
        }
        catch(error){
            onSubmit({isValid: false, error: error})
        }
    }

    

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.frame}`}>
                <div className={styles.form}>
                    <div className={styles.label}>
                    <div className={styles.text_wrapper}>     
                    <span className={styles.optionalIndicator}> {true ? '*' : ''} </span>Set {`${product}`} Data</div>
                    </div>
                    {product === 'KalDocs' && <KalDocsForm project_type= {project_type} processors={processors} setProcessors={setProcessors} submit={handleSubmit}/>}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateProductDataForm);